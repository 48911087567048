import { dayjs } from '@futures.tw/dayjs';
import { HubConnectionState } from '@microsoft/signalr';
import { remove } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { proxy, ref } from 'valtio';
import { devtools, useProxy } from 'valtio/utils';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { _buildSignalrConnection } from '~/modules/signal-wall-2/_buildSignalrConnection';
function createSignalrContext(contextOptions) {
    /** Signalr Instance */
    const _connection = ref(_buildSignalrConnection({
        ws: contextOptions.ws,
        accessTokenFactory: contextOptions.accessTokenFactory,
        version: contextOptions.versions,
    }));
    //
    // 接收整堆整堆的推播，作為原始未加工資料
    _connection.on('LargeTradeQtyReceived', (incoming) => {
        /** YC 說後端那邊用 queue 應該沒辦法幫忙反轉，所以兩筆以上的 data 由前端反轉 */
        if (incoming.length >= 2) {
            incoming = incoming.toReversed();
        }
        debugAPI.FrSignalWall2.log(`on:LargeTradeQtyReceived`, { incomingSourceData: incoming });
        if (incoming.length > 0) {
            store._dataOfRaw = [...incoming, ...store._dataOfRaw];
        }
        if (incoming.length >= 2) {
            store._dataOfRaw = store._dataOfRaw.toSorted((left, right) => {
                return dayjs(right.datetime).time() - dayjs(left.datetime).time();
            });
            //
            // 尊重 filters
            store.data = store._filter([...store._dataOfRaw]);
        }
    });
    //
    // 接收一筆一筆的推播，作為 UI 呈現資料
    _connection.on('LargeTradeQtyReceived', (incoming) => {
        if (incoming.length === 1) {
            store.data = store._filter([...incoming, ...store.data]);
        }
    });
    //
    // 處理 maxCount
    _connection.on('LargeTradeQtyReceived', (incoming) => {
        store.maxCount._update();
    });
    //
    // 其餘情境
    _connection.onreconnected(connectionId => {
        debugAPI.FrSignalWall2.log(`on:onreconnected`, { connectionId });
    });
    _connection.onclose(error => {
        debugAPI.FrSignalWall2.log(`on:onclose`, { error });
    });
    _connection.onreconnecting(error => {
        debugAPI.FrSignalWall2.log(`on:onreconnecting`, { error });
    });
    // logs
    _connection.invoke = new Proxy(_connection.invoke, {
        apply(target, connection, args) {
            debugAPI.FrSignalWall2.log(`.invoke(); ing...`, ...args);
            return Reflect.apply(target, connection, args);
        },
    });
    //
    //
    const store = proxy({
        //
        // states
        data: [],
        _dataOfRaw: [],
        /** state.data 資料列最大儲存數量 */
        maxCount: {
            value: 100,
            set(maxCount) {
                store.maxCount.value = maxCount;
            },
            _update() {
                if (store.data.length >= store.maxCount.value) {
                    store.data = store.data.slice(0, store.maxCount.value);
                }
            },
        },
        get isConnected() {
            return this._connection.state === HubConnectionState.Connected;
        },
        get isDisconnected() {
            return this._connection.state === HubConnectionState.Disconnected;
        },
        //
        // methods
        /**
         * - 重新整理 data
         * - 重新套用 filters
         */
        update() {
            store.data = store._filter(store._dataOfRaw);
            store.maxCount._update();
            return store.data;
        },
        /** 啟用 filter */
        enableFilter(filter) {
            store._filters.push(filter);
            debugAPI.FrSignalWall2.log(`.addFilter(); ed;`, { add: filter, to: store._filters });
        },
        /** 關閉 filter */
        disableFilter(filter) {
            remove(store._filters, currentFilter => currentFilter === filter);
            debugAPI.FrSignalWall2.log(`.removeFilter(); ed;`, { remove: filter, result: store._filters });
        },
        // toggleFilter(filter: FrSignalWall2Types.FilterFn) {
        //   if (store.hasFilter(filter)) {
        //     store.removeFilter(filter)
        //   } else {
        //     store.addFilter(filter)
        //   }
        // },
        // hasFilter(filter: FrSignalWall2Types.FilterFn) {
        //   return store._filters.some(currentFilter => currentFilter === filter)
        // },
        async start() {
            if (store.isConnected) {
                debugAPI.FrSignalWall2.log(`.start(); ing; 已有重複連線...`);
                await store.stop();
            }
            debugAPI.FrSignalWall2.log(`.start(); ing; 正在建立連線...`);
            await store._connection.start();
            debugAPI.FrSignalWall2.log(`.start(); ed; 已連線`);
        },
        async stop() {
            debugAPI.FrSignalWall2.log(`.stop(); ing; 正在斷開連線...`);
            await store._connection.stop();
            debugAPI.FrSignalWall2.log(`.stop(); ed; 已斷開`);
        },
        async subscribe(topic, values) {
            return await _connection.invoke('Subscribe', { [topic]: values }).then(() => {
                if (!store._topicSubscriptions[topic]) {
                    store._topicSubscriptions[topic] = [];
                }
                values.forEach(value => {
                    store._topicSubscriptions[topic]?.push(value);
                });
            });
        },
        async unsubscribe(topic, values) {
            return await _connection.invoke('Unsubscribe', { [topic]: values }).then(() => {
                const currentTopicValues = store._topicSubscriptions[topic];
                values.forEach(removedValue => {
                    if (currentTopicValues) {
                        remove(currentTopicValues, currentValue => currentValue === removedValue);
                    }
                });
            });
        },
        //
        // hooks
        /** 請注意「不要重複使用」 這個hook，以免一直重複建立連線/斷線 */
        useConnect(options) {
            const onConnectedCb = useRef(options?.onConnected || null);
            useEffect(() => {
                store.start().then(() => {
                    onConnectedCb.current?.();
                    debugAPI.FrSignalWall2.log(`.useConnect {} options.onConnected();`, {
                        onConnected: onConnectedCb.current,
                    });
                });
                return () => {
                    store.stop();
                };
            }, []);
        },
        /**
         * 取得「指定filter」的「state」，或者「methods」
         *
         * - 取得 methods 用以客製執行時機： `onClick={() => { filter.toggle().update() }}`
         * - 取得 state 用以客製呈現 UI： `<Checkbox enabled={filter.enabled} />`
         * - 通常提供給 UI 的 input, checkbox, switch, form, 之類的小組件來使用
         *
         * @example
         *   const excludeFutures = pageStore.signal.useFilter(pageStore.filters.excludeFutures)
         *
         *   return (
         *     <Switch
         *       onChange={() => {
         *         excludeFutures.toggle().update()
         *       }}
         *       checked={excludeFutures.enabled}
         *       labelPosition='right'
         *       label='排除股期'
         *     />
         *   )
         */
        useFilter(filter) {
            const state = useProxy(store);
            const [enabled, setEnabled] = useState(false);
            useEffect(() => {
                const hasEnabled = !!state._filters.some(currentFilter => currentFilter === filter);
                setEnabled(hasEnabled);
            }, [filter, state._filters]);
            const toggle = useCallback(() => {
                if (enabled) {
                    store.disableFilter(filter);
                }
                else {
                    store.enableFilter(filter);
                }
                return store;
            }, [enabled, filter]);
            return {
                /** boolean 提供於 UI input 用於呈現啟用/停用狀態 */
                enabled,
                /** 快速切換 指定filter 的啟用/停用狀態 */
                toggle,
            };
        },
        //
        // ! private
        //
        /**
         * @private
         * @example
         *   //
         *   store.addFilter(datum => datum.symbol === '2330')
         *   store.addFilter(datum => datum.symbol === '2886')
         *
         *   // 執行
         *   data = store.filter(data)
         */
        _filter(data) {
            store._filters.forEach(filter => {
                data = data.filter(filter);
            });
            return data;
        },
        /** 已送訂閱成功的 */
        _topicSubscriptions: {},
        /** 當前正在運作的邏輯篩選器 */
        _filters: [],
        // refs
        _connection,
    });
    devtools(store, { name: `FrSignalWall2:${contextOptions.name || 'Context'}` });
    return store;
}
/** 訊號牆 SDK v2 with Signalr */
export const FrSignalWall2 = {
    /**
     * - 在 pageStore 執行 `createSignalrContext` 創建一個新的獨立 context
     * - 然後請參考 範例程式碼
     *
     * @example
     *   // 步驟12345
     *   // 在你的 page 你有一個 page 專用的 pageStore
     *   export const pageStore = proxy({})
     *
     *   //
     *   // 建立一個新的獨立 context
     *   export const pageStore = proxy({
     *     signal: FrSignalWall2.createSignalrContext({
     *       ws: apirc.signalWall2.baseUrl,
     *       accessTokenFactory: () => FrFirebase.auth.currentUser?.getIdToken() || '_UNSET_',
     *     }),
     *   })
     *
     *   //
     *   // 然後在 pageStore 裡面，客製化你的 filters （此舉可保持 filter引用 存在於 store 不變）
     *   export const pageStore = proxy({
     *     //
     *     // 前略
     *     // ......
     *     //
     *     filters: {
     *       excludeFinancial: datum => {
     *         return !/^28.+$/.test(datum.symbol)
     *       },
     *       excludeCrypto: datum => {
     *         return !datum.symbol.includes('USDT')
     *       },
     *       excludeFutures: datum => {
     *         return !datum.symbol.includes('-')
     *       },
     *     } satisfies {
     *       [key: string]: FrSignalWall2Types.FilterFn
     *     },
     *   })
     *
     *   //
     *   // 在 PageCol1 或者 PageRow1 或者 PageProvider 或者 PageLayout 裡面，幫這個 context 建立獨立連線
     *   pageStore.signal.useConnect({
     *     onConnected: () => {
     *       pageStore.signal.subscribe('SignalIds', [
     *         'LargeTradeQty:Crypto',
     *         'LargeTradeQty:Futures',
     *       ])
     *
     *       //
     *       // 預設啟用的 filters
     *       pageStore.signal.addFilter(pageStore.filters.excludeFinancial)
     *       pageStore.signal.addFilter(pageStore.filters.excludeCrypto)
     *
     *       //
     *       // 配置資料列最大儲存數量
     *       pageStore.signal.maxCount.set(100)
     *
     *       //
     *       // 對當前已存在列表資料，整體處理一次
     *       pageStore.signal.update()
     *     },
     *   })
     *
     *   //
     *   // 透過 useFilter 來使 form 元件可以啟用/停用 filters
     *   const excludeCrypto = pageStore.signal.useFilter(pageStore.filters.excludeCrypto)
     *   const excludeFinancial = pageStore.signal.useFilter(pageStore.filters.excludeFinancial)
     *
     *   return (
     *     <Fragment>
     *       <Switch
     *         onClick={() => {
     *           excludeFinancial.toggle().update()
     *         }}
     *         checked={excludeFinancial.enabled}
     *         labelPosition='right'
     *         label='排除金融股'
     *         css={switchCss}
     *       />
     *
     *       <Switch
     *         onChange={() => {
     *           excludeCrypto.toggle().update()
     *         }}
     *         checked={excludeCrypto.enabled}
     *         labelPosition='right'
     *         label='排除幣圈'
     *         css={switchCss}
     *       />
     *     </Fragment>
     *   )
     *
     *   //
     *   // 或者
     *   // 透過命令句來自己組合
     *   pageStore.signal.enableFilter(pageStore.filters.excludeCrypto)
     *   pageStore.signal.disableFilter(pageStore.filters.excludeCrypto)
     *   pageStore.signal.update()
     */
    createSignalrContext,
};
