import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import urlcat from 'urlcat';
import { ENV } from '~/configs/ENV';
export function _buildSignalrConnection(options) {
    const connection = new HubConnectionBuilder(); // .withHubProtocol(new MessagePackHubProtocol())
    const _versions = ['buildSignalrConnection', 'jwtRequired', ...(options.version || [])];
    return connection
        .withUrl(urlcat(options.ws, {
        version: _versions.join(','),
        commithash: ENV.COMMITHASH,
    }), {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        logger: LogLevel.Information,
        accessTokenFactory: options.accessTokenFactory,
    })
        .withAutomaticReconnect([2, 5, 15, 30, 60, 120, 300])
        .build();
}
