import { useDisclosure } from '@mantine/hooks';
import { Modal, Autocomplete, Chip, Switch, Divider } from '@mantine/core';
import { memo, useState } from 'react';
import styled from '@emotion/styled';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { AiOutlineSearch } from 'react-icons/ai';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { removeAfterWhitespace } from '~/modules/symbolPlatform/removeAfterWhitespace';
import { css } from '@emotion/react';
import { IoMdSettings } from 'react-icons/io';
import { pageStore } from '~/pages/futuresai/large-trade/pageStore';
/** 篩選張數 */
const tickyQtyConfig = [10, 50, 100, 200, 300, 400];
/** 篩選金額 */
const tickValueCOnfig = [500, 1000, 2000, 5000];
/** 篩選多空大單方向 */
const tickBSConfig = ['all', 'b', 's'];
export const DashboardModal = memo(function DashboardModal() {
    const excludeFutures = pageStore.signal.useFilter(pageStore.filters.excludeFutures);
    const excludeFinancial = pageStore.signal.useFilter(pageStore.filters.excludeFinancial);
    const excludeCrypto = pageStore.signal.useFilter(pageStore.filters.excludeCrypto);
    const [opened, { open, close }] = useDisclosure(false);
    const state = useSnapshot(largerTradeStore);
    const filterSymbolGroupName = 'signal_wall_fliter';
    const filterSymbolsState = useSymbolWatchList(filterSymbolGroupName);
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const searchDefaultSymbols = Object.entries(dictionary).map(s => ({
        symbol: s[0],
        name: s[1],
    }));
    const [inputValue, setInputValue] = useState('');
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const symbolNumber = removeAfterWhitespace(inputValue);
            if (/^\d{4}$/.test(symbolNumber)) {
                filterSymbolsState.acts.addSymbol(symbolNumber, 20);
                setInputValue('');
            }
        }
    };
    return (<div css={css `
        display: flex;
        width: 100%;
        height: 100%;
      `}>
      <Modal opened={opened} onClose={close} title='盤中大單動向設定' css={css `
          ${fill_vertical_cross_center};
          gap: 4px;
        `}>
        <span>最低大單張數</span>
        <classes.board>
          {tickyQtyConfig.map(value => {
            return (<Chip checked={state.filterTickQty === value} key={value} onClick={() => (largerTradeStore.filterTickQty = value)} variant='filled' css={chipCss}>
                {value}張
              </Chip>);
        })}
        </classes.board>
        <Divider my='sm'/>
        <span>最低大單金額</span>
        <classes.board>
          {tickValueCOnfig.map(value => {
            return (<Chip checked={state.filterTickValue === value} key={value} onClick={() => (largerTradeStore.filterTickValue = value)} variant='filled' css={chipCss}>
                {value}萬
              </Chip>);
        })}
        </classes.board>
        <Divider my='sm'/>
        <span>大單方向</span>
        <classes.board>
          {tickBSConfig.map(value => {
            return (<Chip checked={state.filterBS === value} key={value} variant='filled' color={state.filterBS === 'b' ? 'red' : state.filterBS === 's' ? 'green' : 'blue'} onClick={() => (largerTradeStore.filterBS = value)} css={chipCss}>
                {value === 'b' ? '買進' : value === 's' ? '賣出' : '全部'}
              </Chip>);
        })}
        </classes.board>
        <Divider my='sm'/>
        <span>排除名單</span>
        <classes.board>
          <Switch onClick={() => {
            excludeFinancial.toggle().update();
        }} checked={excludeFinancial.enabled} labelPosition='right' label='排除金融股' css={switchCss}/>
          <Switch onChange={() => {
            excludeFutures.toggle().update();
        }} checked={excludeFutures.enabled} labelPosition='right' label='排除股期' css={switchCss}/>
          <Switch onChange={() => {
            excludeCrypto.toggle().update();
        }} checked={excludeCrypto.enabled} labelPosition='right' label='排除 USDT' css={switchCss}/>
        </classes.board>
        <Divider my='sm'/>
        <span>篩選商品</span>
        <classes.search>
          <classes.board>
            <Switch onClick={() => (largerTradeStore.filterSymbols =
            !state.filterSymbols && filterSymbolsState.state.symbolListArray.length > 0)} checked={state.filterSymbols && filterSymbolsState.state.symbolListArray.length > 0} labelPosition='right' label='篩選自訂商品' css={switchCss}/>
            <Switch onClick={() => (largerTradeStore.markSymbols =
            !state.markSymbols && filterSymbolsState.state.symbolListArray.length > 0)} checked={state.markSymbols && filterSymbolsState.state.symbolListArray.length > 0} labelPosition='right' label='標記自訂商品' css={switchCss}/>
          </classes.board>

          <Autocomplete icon={<AiOutlineSearch size={22}/>} placeholder='搜尋商品代號' data={searchDefaultSymbols.map(s => s.symbol + ' ' + s.name)} variant='unstyled' limit={100} maxDropdownHeight={200} value={inputValue} onChange={(value) => setInputValue(value)} onItemSubmit={item => {
            const symbolNumber = removeAfterWhitespace(inputValue);
            if (/^\d{4}$/.test(symbolNumber)) {
                filterSymbolsState.acts.addSymbol(symbolNumber, 20);
                setInputValue('');
            }
        }} onKeyDown={handleKeyDown} css={css `
              background-color: #e5e9ed;
              width: 164px;
            `}/>
        </classes.search>
        <classes.symbolGroup>
          {filterSymbolsState.state.symbolListArray.map(s => {
            return (<classes.symbolChip key={s} onClick={() => filterSymbolsState.acts.removeSymbol(s)}>
                {s}
              </classes.symbolChip>);
        })}
        </classes.symbolGroup>
      </Modal>

      <classes.settingBoard onClick={open}>
        <classes.settingTitle>現貨大單數量: {state.filterTickQty}張以上</classes.settingTitle>
        <classes.settingTitle>現貨大單金額: {state.filterTickValue}萬以上</classes.settingTitle>
        <div css={css `
            margin-left: auto;
            display: flex;
            align-items: center;
          `}>
          監控設定
          <IoMdSettings fontSize={14} css={css `
              width: 14px;
              height: 14px;
            `}/>
        </div>
      </classes.settingBoard>
    </div>);
});
const chipCss = css `
  .mantine-Chip-root {
    width: 80px;
    height: 28px;
  }
  .mantine-Chip-label {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 28px;
  }
  .mantine-Chip-input {
    width: 0;
    height: 0;
  }
  .mantine-Chip-checkIcon {
    width: 0;
  }
  .mantine-Chip-iconWrapper {
    width: 0;
  }
  .mantine-1v2fnwp[data-checked] {
    width: 80px;
    height: 28px;
  }
  .mantine-1v2fnwp {
    width: 80px;
    height: 28px;
  }
`;
const switchCss = css `
  .mantine-Switch-label {
    padding-left: 2px;
    font-size: 14px;
  }
`;
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    board: styled.div `
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 4px;
    gap: 8px;
    align-items: start;
    & > div {
      cursor: pointer;
    }
  `,
    search: styled.div `
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 4px;
    gap: 8px;
    align-items: start;
    & > div {
      cursor: pointer;
    }
  `,
    symbolGroup: styled.div `
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 4px;
    gap: 8px;
    background-color: #f8f8f8;
    height: 80px;
  `,
    symbolChip: styled.div `
    background-color: #e5e9ed;
    border-radius: 5px;
    padding: 0 4px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: #ffc9cd;
      text-decoration: line-through;
    }
  `,
    settingBoard: styled.div `
    ${fill_horizontal_cross_center};
    font-size: 14px;
    cursor: pointer;
    color: #252525;
    background-color: #eeeeee;
    gap: 10px;
    border-radius: 4px;
    padding: 0 4px;
  `,
    settingTitle: styled.div `
    padding: 0 4px;
  `,
};
export const largerTradeStore = proxy({
    /** 要篩選的股票 */
    filterSymbols: false,
    /** 要標記的的股票 */
    markSymbols: false,
    /** 排除金融股 */
    excludeFinancial: true,
    /** 排除金融股 */
    excludeETF: true,
    /** 排除期貨 */
    excludeFutures: false,
    /** 要篩選的方向 */
    filterBS: 'all',
    /** 要篩選的大單成交金額 */
    filterTickValue: 500,
    /** 要篩選的大單數量 */
    filterTickQty: 100,
    /** 未知 */
    //inputValue: '' as string,
});
