import { proxy } from 'valtio';
import { apirc } from '~/configs/apirc';
import { FrFirebase } from '~/modules/SDK/firebase/FrFirebase';
import { FrSignalWall2 } from '~/modules/signal-wall-2/FrSignalWall2';
export const pageStore = proxy({
    signal: FrSignalWall2.createSignalrContext({
        ws: apirc.signalWall2.baseUrl,
        versions: ['futuresai', 'large-trade'],
        accessTokenFactory: () => FrFirebase.auth.currentUser?.getIdToken() || '_UNSET_',
    }),
    filters: {
        excludeFinancial: datum => {
            return !/^28.+$/.test(datum.symbol);
        },
        excludeCrypto: datum => {
            return !datum.symbol.includes('USDT');
        },
        excludeFutures: datum => {
            return !datum.symbol.includes('-');
        },
    },
});
